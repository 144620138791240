import React from "react";
import logo_img from "./images/logo.svg";

const Header = (props) => {
    return (
        <div className="row EvColorGrisLight align-items-center">
            <div className="col-6">
                <img src={logo_img} className="img-fluid logoEV" alt="logo" />
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">

                <select
                    id="language"
                    value={props.language}
                    onChange={props.handleLanguageChange}
                    className="custom-select w-auto"
                >
                    <option value="default">Español</option>
                    <option value="en">English</option>
                </select>
            </div>
        </div>
    );
};

export default Header;
